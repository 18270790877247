<template>
  <div>
    <vx-card
      ref="filterCard"
      title="Filters"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer">
        <form ref="form" slot-scope="{ validate }" autocomplete="off">
          <div class="vx-row">
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75 mr-3">
                <span class="text-primary">*</span>From
              </label>
              <flat-pickr
                :config="configFromdateTimePicker"
                v-model="items.from"
                placeholder="From Date"
                @on-change="onFromChange"
              />
            </div>

            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75 mr-3">
                <span class="text-primary">*</span>To
              </label>
              <flat-pickr
                :config="configTodateTimePicker"
                v-model="items.to"
                placeholder="To Date"
                @on-change="onToChange"
              />
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Company
              </label>
              <validation-provider
                name="company_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  label="name"
                  :reduce="(name) => name.id"
                  :options="companies"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="items.company_id"
                  class="mb-4"
                  placeholder="Company"
                  :class="{ 'is-valid': !!errors[0] }"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75"> Departments</label>

              <v-select
                :reduce="(name) => name.id"
                label="name"
                :options="departments"
                name="department_id"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.department_id"
                class="mb-4"
                placeholder="Department"
              />
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">Job Title</label>
              <v-select
                :reduce="(name) => name.id"
                label="name"
                name="jobtitle_id"
                :options="jobTitles"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.jobtitle_id"
                class="mb-4"
                placeholder="Job Title"
              />
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mt-6">
              <vs-button
                type="filled"
                @click="validate().then(addNewFilter)"
                class="w-full mb-2"
                >Filters</vs-button
              >
            </div>
          </div>
        </form>
      </validation-observer>
    </vx-card>
    <vx-card class="vs-con-loading__container" id="div-with-loading">
      <vs-prompt
        title="Export To Excel"
        class="export-options"
        @cancle="clearFields"
        @accept="exportToExcel"
        accept-text="Export"
        @close="clearFields"
        :active.sync="activePrompt"
      >
        <vs-input
          v-model="fileName"
          placeholder="Enter File Name.."
          class="w-full"
        />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
      </vs-prompt>

      <div class="export-table">
        <vs-table
          class="style-table"
          ref="table"
          :data="itemsData"
          search
          pagination
          :max-items="itemsPerPage"
        >
          <template slot="header">
            <!-- ITEMS PER PAGE -->
            <vs-dropdown
              vs-trigger-click
              class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
              <div
                class="
                  p-4
                  border border-solid
                  d-theme-border-grey-light
                  rounded-full
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-between
                  font-medium
                "
              >
                <span class="mr-2"
                  >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                  {{
                    itemsData.length - currentPage * itemsPerPage > 0
                      ? currentPage * itemsPerPage
                      : itemsData.length
                  }}
                  From {{ queriedItems }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 25">
                  <span>25</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

            <vs-button @click="activePrompt = true" class="mb-4">
              <i class="vs-icon material-icons mr-1">save_alt</i>
              Export
            </vs-button>
          </template>

          <template slot="thead">
            <vs-th> code </vs-th>
            <vs-th> user </vs-th>
            <vs-th> company </vs-th>
            <vs-th> category </vs-th>
            <vs-th> area </vs-th>
            <vs-th> from_day </vs-th>
            <vs-th> to_day </vs-th>
            <vs-th> type </vs-th>
            <vs-th> sub_type </vs-th>
            <vs-th> job_title </vs-th>
            <vs-th> latency </vs-th>
            <vs-th> from_time </vs-th>
            <vs-th> to_time </vs-th>
            <vs-th> total_working_time </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ data[indextr].code }}</vs-td>
              <vs-td>{{ data[indextr].user }}</vs-td>
              <vs-td>{{ data[indextr].company }}</vs-td>
              <vs-td>{{ data[indextr].category }}</vs-td>
              <vs-td>{{ data[indextr].area }}</vs-td>
              <vs-td>{{ data[indextr].from_day }}</vs-td>
              <vs-td>{{ data[indextr].to_day }}</vs-td>
              <vs-td>{{ data[indextr].type }}</vs-td>
              <vs-td>{{ data[indextr].sub_type }}</vs-td>
              <vs-td>{{ data[indextr].job_title }}</vs-td>
              <vs-td>{{ data[indextr].latency }}</vs-td>
              <vs-td>{{ data[indextr].from_time }}</vs-td>
              <vs-td>{{ data[indextr].to_time }}</vs-td>
              <vs-td>{{ data[indextr].total_working_time }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import endpoints from "../../../endpoints";
import axios from "@/axios.js";
import vSelect from "vue-select";

// Store Module
import moduleHrReportManagement from "@/store/hr-report-management/moduleHrReportManagement.js";
import moduleCompaniesManagement from "@/store/get-all-companies/moduleCompaniesManagement.js";
import moduleDepartmentManagement from "@/store/get-all-departments/moduleDepartmentManagement.js";
import moduleJobTitleManagement from "@/store/get-all-jobs/moduleJobTitleManagement.js";

import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});
extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});
export default {
  components: {
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      itemsPerPage: 10,
      isMounted: false,
      departments: [],
      jobTitles: [],
      department: null,
      items: {
        from: null,
        to: null,
        company_id: null,
        department_id: null,
        jobtitle_id: null,
      },
      configFromdateTimePicker: {
        allowInput: true,
        minDate: null,
        maxDate: new Date(),
      },
      configTodateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },

      fileName: "",
      formats: ["xlsx", "csv", "txt", "dbf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [
        "code",
        "user",
        "company",
        "category",
        "area",
        "from_day",
        "to_day",
        "type",
        "sub_type",
        "latency",
        "from_time",
        "to_time",
        "total_working_time",
      ],
      headerVal: [
        "code",
        "user",
        "company",
        "category",
        "area",
        "from_day",
        "to_day",
        "type",
        "sub_type",
        "latency",
        "from_time",
        "to_time",
        "total_working_time",
      ],
      activePrompt: false,
    };
  },
  watch: {
    "items.company_id"(obj) {
      this.items.department_id = null;
      this.items.jobtitle_id = null;
      this.departments = [];
      this.jobTitles = [];
      // console.log('obj', obj)
      if (obj) {
        this.$vs.loading();
        axios
          .get(`${endpoints.API_URL}company/${obj}/departments`)
          .then((response) => {
            // console.log('response.data.response.data', response.data.response.data)
            this.$vs.loading.close();
            this.departments = response.data.response.data;
          });
      }
    },
    "items.department_id"(obj) {
      this.items.jobtitle_id = null;
      this.jobTitles = [];
      if (obj) {
        this.$vs.loading();
        axios
          .get(`${endpoints.API_URL}department/${obj}/job_titles`)
          .then((response) => {
            this.$vs.loading.close();
            this.jobTitles = response.data.response.data;
          });
      }
    },
  },
  computed: {
    companies() {
      return this.$store.state.company.items;
    },
    itemsData() {
      return this.$store.state.hrReportManagement.items;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.itemsData.length;
    },
  },
  methods: {
    getCompanies() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      this.$store.dispatch("company/fetchItemsCompanies").then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    exportToExcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const list = this.itemsData;
        const data = this.formatJson(this.headerVal, list);

        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat,
        });
        this.clearFields();
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // Add col name which needs to be translated

          // if (j === "attendances") {
          //   // console.log(JSON.stringify(v[j]));
          //   return JSON.stringify(v[j]);
          // } else if (j === "areas") {
          //   return v[j].map((i) => i.name);
          // } else {
          //   return v[j];
          // }

          return v[j];
        })
      );
    },
    clearFields() {
      (this.filename = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    addNewFilter(params) {
      if (!this.items.from || !this.items.to || !this.items.company_id) {
        this.$vs.notify({
          title: "Error",
          text: "Make sure select the main filters ",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return;
      }
      if (params) {
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
        let params = {
          from: this.items.from,
          to: this.items.to,
          company_id: this.items.company_id,
          jobtitle_id: this.items.jobtitle_id,
        };
        console.log('params', params)
        this.$store.dispatch("hrReportManagement/fetchItemsAllReport", params)
          .then((response) => {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            if (response.data.response.data.length === 0) {
              this.$vs.notify({
                title: "Information",
                text: "No data available",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.$vs.notify({
              title: "Error",
              text: error.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      } else {
        // form have errors
      }
    },
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(500);
      this.items.from = this.items.to = this.items.company_id = this.items.jobtitle_id = this.items.department_id = null;
      // Reset Grid Filter
    },
    getDepartments() {
      this.$vs.loading();
      this.$store.dispatch("department/getAlls").then((response) => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getJobTitles() {
      this.$vs.loading();
      this.$store.dispatch("jobTitle/getAlls").then((response) => {
        this.$vs.loading.close();
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
  },
  mounted() {
    this.getCompanies();
    this.getDepartments();
    this.getJobTitles();
    this.isMounted = true;
  },
  created() {
    if (!moduleHrReportManagement.isRegistered) {
      this.$store.registerModule(
        "hrReportManagement",
        moduleHrReportManagement
      );
      moduleHrReportManagement.isRegistered = true;
    }
    if (!moduleCompaniesManagement.isRegistered) {
      this.$store.registerModule("company", moduleCompaniesManagement);
      moduleCompaniesManagement.isRegistered = true;
    }
    if (!moduleDepartmentManagement.isRegistered) {
      this.$store.registerModule("department", moduleDepartmentManagement);
      moduleDepartmentManagement.isRegistered = true;
    }
    if (!moduleJobTitleManagement.isRegistered) {
      this.$store.registerModule("jobTitle", moduleJobTitleManagement);
      moduleJobTitleManagement.isRegistered = true;
    }
  },
};
</script>
<style lang="scss">
.vs-con-table td.td-check {
  border: 1px solid #dcdfe6;
  border-right: 0;
}
.vs-con-table td.td-check .vs-icon {
  font-size: 1.5rem;
  vertical-align: middle;
}
[dir] .vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
  padding: 10px 15px;
  border: 1px solid #dcdfe6;
  color: #606266;
  vertical-align: bottom;
  border-bottom: 1px solid #dcdfe6;
  padding-right: 1.5em;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
[dir]
  .vs-con-table
  .vs-con-tbody
  .vs-table--tbody-table
  .tr-values
  .vs-table--td {
  padding: 0.75em;
  vertical-align: top;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.vs-table--tbody-table tr.tr-expand .content-tr-expand {
  padding: 30px;
  background-color: #fbfbfb;
}
.expand-table {
  border-collapse: collapse;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  border: 1px solid #dcdfe6;
}
.expand-table thead th {
  color: #606266;
  vertical-align: bottom;
  border: 1px solid #dcdfe6;
  padding: 0.75em 1.5em 0.75em 0.75em;
  padding-right: 1.5em;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
.expand-table td {
  padding: 0.75em;
  font-size: 0.85rem;
  vertical-align: top;
  border: 1px solid #dcdfe6;
  color: #606266;
}
</style>
